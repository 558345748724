<template>
  <div id="overlay" v-if="overlay"></div>
  <!--        <pre>{{ vv._vuelidate_11.content.$model }}</pre>-->
  <form @submit.prevent="handleSubmit" class="p-fluid" autocomplete="off">

    <div class="grid">
      <div class="col-12 lg:col-4 xl:col-4">
        <!--        tdistribuidor-->
        <EditLabel ref="tdistribuidor" :input="distributor.tdistribuidor" title="Nombre" :required="true"/>
        <!--        trif_distribuidor-->
        <EditLabel ref="trif_distribuidor" :input="distributor.trif_distribuidor" title="RIF" :required="true"/>
        <!--        id_cod_reg_cona-->
<!--        <EditLabel ref="nporcentaje_comision" :input="distributor.nporcentaje_comision" title="Porcentaje de Comision" :required="true"/>-->
      </div>
      <div class="col-12 lg:col-4 xl:col-4">
        <EditLabel ref="ncant_prestamos" :input="distributor.ncant_prestamos" title="Préstamos" />
      </div>
      <div class="col-12 lg:col-4 xl:col-4">
        <!--        cstatus_distribuidor-->
        <EditDropdown
            ref="cstatus_distribuidor"
            :options="statusList"
            optionLabel="label"
            optionValue="code"
            :selectedInput="distributor.cstatus_distribuidor"
            @onChange="onChangeStatus"
            title="Estatus"
        />
        <EditLabel ref="dcreacion" :input="formatDate(distributor.dcreacion)" title="Fecha de creaciôn" disabled/>

      </div>
    </div>

    <!--  <Button type="button" @click="vv.$touch()">$touch</button>-->
    <div class="flex justify-content-end">
      <Button
          class="p-button-raised" icon="pi pi-save"
          type="submit"
          :disabled="vv.$errors[0] || !vv.$anyDirty"
      />
    </div>
  </form>

</template>

<script>

import useVuelidate from '@vuelidate/core'
import EditLabel from "@/components/misc/Labels/EditLabel";

import MainService from "@/service/backend/MainService";
import DistributorService from "@/service/backend/DistributorService";
import generalMixin from "@/mixins/generalMixin";
import EditDropdown from "@/components/misc/Labels/EditDropdown";

export default {
  name: "Tab1",
  components: {EditDropdown, EditLabel},
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService;
    this.distributorService = new DistributorService;
  },
  setup() {
    let vv = useVuelidate()
    return {vv};
  },
  props: {
    distributor: Object,
  },
  data() {
    return {
      overlay: false,
      statusList: null,
    }
  },
  async mounted() {
    this.statusList = this.getEntityStatus();
    
  },
  methods: {
    onChangeStatus(cstatus_distribuidor) {
      this.selectedStatus = cstatus_distribuidor.value;
    },
    handleSubmit() {
      this.submitted = true;
      const distributor = {
        id_distribuidor: this.distributor.id,
        tdistribuidor: this.$refs.tdistribuidor.content,
        cstatus_distribuidor: this.selectedStatus,//this.$refs.cstatus_distribuidor.content,
      };
      this.saveDistributor(distributor);
    },
    async saveDistributor(distributor) {
      try {
        this.overlay = distributor;
        const response = await this.distributorService.updateDistributorDetails(distributor);
        if (response.error) {
          this.$toast.add({
            severity: 'error',
            summary: '',
            detail: 'No se pudo editar el distribuidor | ' + response.error,
            life: 10000
          });
          this.overlay = false;
        } else {
          this.$toast.add({severity: 'success', summary: '', detail: `Distribuidor ${distributor.tdistribuidor} editado`, life: 3000});
          this.$emit('close', close);
        }

      } catch (e) {
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'No se pudo editar el distribuidor | ' + e.message,
          life: 10000
        });
      }
      this.overlay = false;
    }
  }

}
</script>
<style>
.municipio-error {
  margin-top: -10px;
  margin-left: 10px;
}
</style>